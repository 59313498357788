<template>
  <div class="views-page p-4">
    <b-loading
      :is-full-page="true"
      v-model="is_loading"
      :can-cancel="true"
    />
    <div
      class="info-line"
      :class="last_message.main_color"
    />
    <div class="column p-0 tag-parent-mobile is-flex is-flex-wrap-wrap">
      <span
        class="tag-mobile has-text-weight-medium is-size-6 tag px-4 py-4 mr-3 mb-2"
        v-for="(message, id) in last_message['cards'] || []"
        :key="message.id"
        :class="message.color"
      >{{ message.name }}</span>
    </div>
    <h1 class="has-text-centered has-text-weight-semibold is-size-5">{{ device_name }}</h1>
    <div v-if="device_name" class="is-divider my-4"></div>
    <div>
      <div
        class="hello"
        ref="chartdiv"
      ></div>
    </div>
    <div
      class="is-flex is-flex-wrap-wrap mt-3"
      v-if="is_available"
    >
      <div class="block has-background-white-ter p-4 is-flex is-flex-wrap-wrap is-justify-content-space-between" style="flex: 1 0 100%" v-if="markers.length > 0 || trend_marker_ranges.length > 0">
        <div class="is-flex-grow-1">
          <div
            v-if="trend_marker_ranges.length > 0"
          >
<!--            <b-checkbox-->
<!--              class="mr-5"-->
<!--              @input.native="handleTrendMarkerChange($event.target.checked)"-->
<!--            >-->
<!--              Показывать названия-->
<!--            </b-checkbox>-->
            <div style="max-width: 500px; width: 100%" class="mb-3">
              <b-dropdown
                ref="multipleDropdown"
                aria-role="list"
                scrollable
                max-height="200"
                expanded
              >
                <template #trigger>
                  <b-button
                    type="is-secondary"
                    icon-pack="fas"
                    outlined
                    class="btn-icon is-fullwidth is-flex is-justify-content-space-between"
                    icon-right="fa-chevron-down"
                  >
                    <span>{{ getDropdownLabel() }}</span>
                  </b-button>
                </template>
                <b-dropdown-item v-if="trend_marker_ranges && trend_marker_ranges.length" custom style="cursor: pointer" aria-role="listitem">
                  <b-checkbox
                    :value="selectedMarkerRanges.length === trend_marker_ranges.length"
                    :indeterminate="selectedMarkerRanges.length !== trend_marker_ranges.length && selectedMarkerRanges.length !== 0"
                    @input.native="selectAllMarkerRanges"
                  >Выбрать все
                  </b-checkbox>
                  <!--              <span @click="selectAllNames">{{ selectedNames.length === names[selected_channel].length ? 'Очистить' : 'Выбрать все' }}</span>-->
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="(range, idx) in trend_marker_ranges || []"
                  custom
                  :key="range.title"
                  :value="range.title"
                  aria-role="listitem"
                >
                  <b-checkbox
                    v-model="selectedMarkerRanges"
                    :native-value="range.title"
                    @input.native="onMarkerRangeCheck($event.target.checked, range.title)"
                  >
                    <div class="is-flex is-align-items-center">
                      {{ range.title }}
                    </div>
                  </b-checkbox>
                  <!--              <span>{{ name }}</span>-->
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <b-field v-if="speedValue" label="Оборотная частота">
            <b-input
              v-model="selectedSpeedOption"
              class="mr-2"
              type="number"
              @input="debouncedOnSpeedChange($event)"
            >
            </b-input>
            <b-select
              placeholder="Количество"
              @input="onSpeedChange"
            >
              <option
                v-for="(option, idx) in speedOptions"
                :value="option.value"
                :key="idx"
              >
                {{ option.label }}
              </option>
            </b-select>
          </b-field>
          <div v-if="markers.length > 0">
            <p class="has-text-weight-semibold mb-2" style="font-size: 18px">Markers</p>
            <div class="is-flex">
              <span
                v-for="(marker, idx) in markers"
                :key="`marker-${idx}`"
              >
                <b-tooltip :label="getMarkerLabel(marker)" type="is-light is-info">
                  <b-checkbox
                    v-model="selected_markers"
                    :native-value="marker"
                    class="mr-5"
                    @input.native="handleMarkerChange($event.target.checked, marker)"
                  >
                    {{ marker }}
                  </b-checkbox>
                </b-tooltip>
              </span>
            </div>
          </div>
          <div class="is-flex is-flex-direction-column mt-2">
            <p class="has-text-weight-semibold mb-2" style="font-size: 18px">Уровни</p>
            <div v-if="alarms" class="warning">
              <b-checkbox
                v-model="alarmChecked"
                @input.native="handleAlarmWarningChange('alarm', $event.target.checked)"
              >
                Тревога
                <span class="line-alarm"></span>
              </b-checkbox>
            </div>
            <div v-if="warnings" class="warning">
              <b-checkbox
                v-model="warningChecked"
                @input.native="handleAlarmWarningChange('warning', $event.target.checked)"
              >
                Предупреждение
                <span class="line-warning"></span>
              </b-checkbox>
            </div>
          </div>
        </div>
        <div>
          <p>Обороты: <strong>{{ speed }} об/мин</strong></p>
<!--          <p>RMS: <strong>{{ rms }} <span v-html="rms_unit"></span></strong></p>-->
        </div>
      </div>
      <div style="width: 100%" class="mb-5">
        <h1 class="has-text-weight-semibold is-size-5 mb-2">Главный спектр</h1>
        <div
          class="mr-4 px-4 pt-4 pb-2 is-flex is-justify-content-space-around has-background-light spectra__filters"
          style="width: 100%; gap: 1rem;"
          :style="{ borderLeft: `2px solid ${series_colors[0]}` }"
        >
          <b-field label="Выберите тип">
            <b-select
              placeholder="Типы"
              v-model="main_spectrum.type"
              expanded
              @input="main_spectrum.channel && fetchSpectraSources()"
            >
              <option
                v-for="(type, idx) in types"
                :value="type"
                :key="idx"
              >
                {{ getTypeName(type) }}({{type}})
              </option>
            </b-select>
          </b-field>
          <b-field label="Выберите канал">
            <b-select
              placeholder="Каналы"
              v-model="main_spectrum.channel"
              expanded
              @input="main_spectrum.type ? fetchSpectraSources() : undefined"
            >
              <option
                v-for="(option, idx) in channels"
                :value="option"
                :key="idx"
              >
                {{ option }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Выберите источник">
            <b-datepicker
              position="is-top-left"
              :first-day-of-week="1"
              icon="calendar-days"
              icon-pack="fas"
              placeholder="Выберите даты"
              :disabled="sources.length === 0"
              :loading="sources_is_loading"
              :close-on-click="false"
              :events="dates"
              @input="onDateChange"
            >
              <b-field class="is-flex is-flex-direction-column" label="Выберите время">
                <b-select
                  v-model="main_spectrum.source"
                  placeholder="Выберите время"
                  icon="clock"
                  icon-pack="fas"
                  class="spectrum-source__time"
                >
                  <option v-for="(time, index) in times" :key="`time-${index}`" :value="time">{{ getTimeOnly(time) }}</option>
                </b-select>
              </b-field>
            </b-datepicker>
          </b-field>
        </div>
      </div>
      <div class="is-divider mb-4" />
      <b-collapse
        :open="false"
        style="width: 100%"
        animation="slide"
        aria-id="contentIdForA11y3"
      >
        <template #trigger="props">
          <div
            class="is-flex is-justify-content-space-between"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open"
          >
            <h1 class="has-text-weight-semibold is-size-5 mb-2">Эталонные спектры</h1>
            <b-icon
              size="is-medium"
              :icon="props.open ? 'chevron-up' : 'chevron-down'">
            </b-icon>
          </div>
        </template>

        <div
          v-for="(spectrum, spectrum_idx) in reference_spectra"
          :key="'spectrum_' + spectrum_idx"
          class="mr-4 px-4 pt-4 is-flex is-justify-content-space-around has-background-light spectra__filters"
          style="width: 100%; gap: 1rem"
          :style="{ borderLeft: `2px solid ${series_colors[spectrum_idx + 1] || 'transparent'}` }"
        >
          <b-field label="Выберите тип">
            <b-select
              placeholder="Типы"
              v-model="spectrum.type"
              expanded
              @input="spectrum.channel && fetchSpectraSources(spectrum_idx)"
            >
              <option :value="null">
                Не выбрано
              </option>
              <option
                v-for="(type, idx) in types"
                :value="type"
                :key="idx"
              >
                {{ getTypeName(type) }}({{type}})
              </option>
            </b-select>
          </b-field>
          <b-field label="Выберите канал">
            <b-select
              placeholder="Каналы"
              v-model="spectrum.channel"
              expanded
              @input="spectrum.type && fetchSpectraSources(spectrum_idx)"
            >
              <option
                v-for="(option, idx) in channels"
                :value="option"
                :key="idx"
              >
                {{ option }}
              </option>
            </b-select>
          </b-field>
          <b-field
            label="Выберите источник"
            :type="reference_times[spectrum_idx].length && !reference_spectra[spectrum_idx].source ? 'is-danger' : undefined"
          >
            <b-datepicker
              position="is-top-left"
              :first-day-of-week="1"
              icon="calendar-days"
              icon-pack="fas"
              placeholder="Выберите даты"
              :disabled="reference_sources[spectrum_idx].length === 0"
              :close-on-click="false"
              :events="reference_events[spectrum_idx]"
              @input="onDateChange($event, spectrum_idx)"
            >
              <b-field class="is-flex is-flex-direction-column" label="Выберите время">
                <b-select
                  v-model="reference_spectra[spectrum_idx].source"
                  placeholder="Выберите время"
                  icon="clock"
                  icon-pack="fas"
                  class="spectrum-source__time"
                >
                  <option
                    v-for="(time, index) in reference_times[spectrum_idx]"
                    :key="`time_${index}`"
                    :value="time"
                  >{{ getTimeOnly(time) }}</option>
                </b-select>
              </b-field>
            </b-datepicker>
<!--            <b-datetimepicker-->
<!--              v-model="spectrum.source"-->
<!--              position="is-top-left"-->
<!--              :first-day-of-week="1"-->
<!--              icon="calendar-days"-->
<!--              icon-pack="fas"-->
<!--              placeholder="Выберите даты"-->
<!--              :disabled="reference_sources[spectrum_idx].length === 0"-->
<!--              @input="onDateChange($event, spectrum_idx)"-->
<!--            >-->
<!--              <template #right>-->
<!--                <b-dropdown-->
<!--                  v-if="reference_times[spectrum_idx].length > 1"-->
<!--                  scrollable-->
<!--                  max-height="180"-->
<!--                  aria-role="list"-->
<!--                  append-to-body-->
<!--                  position="is-bottom-left"-->
<!--                >-->
<!--                  <template #trigger>-->
<!--                    <b-button-->
<!--                      size="is-small"-->
<!--                      label="Другое время"-->
<!--                    />-->
<!--                  </template>-->
<!--                  <b-dropdown-item-->
<!--                    v-for="(time, index) in reference_times[spectrum_idx]"-->
<!--                    :key="index"-->
<!--                    :value="time" aria-role="listitem"-->
<!--                    @click="reference_spectra[spectrum_idx].source = new Date(time)"-->
<!--                  >-->
<!--                    <div class="media">-->
<!--                      <div class="media-content">-->
<!--                        <h3>{{getTimeOnly(time)}}</h3>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </b-dropdown-item>-->
<!--                </b-dropdown>-->
<!--              </template>-->
<!--            </b-datetimepicker>-->
<!--            <b-select-->
<!--              v-model="spectrum.source"-->
<!--              :placeholder="reference_sources[spectrum_idx].length === 0 ? 'пусто' : 'Выберите'"-->
<!--              expanded-->
<!--            >-->
<!--              <option-->
<!--                v-for="(src, idx) in reference_sources[spectrum_idx]"-->
<!--                :key="`s-${idx}`"-->
<!--                :value="src"-->
<!--              >{{ src }}-->
<!--              </option>-->
<!--            </b-select>-->
          </b-field>
        </div>
      </b-collapse>
    </div>
    <div class="is-flex mt-5">
      <b-button
        label="Применить"
        class="ml-auto"
        type="is-info"
        :disabled="!main_spectrum.type || !date || !main_spectrum.channel || !main_spectrum.source"
        @click="fetchSpectra"
      />
    </div>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import { mapActions } from "vuex"

// am4core.useTheme(am4themes_animated)

export default {
  props: ["device_id", "deviceName"],
  data() {
    return {
      device_name: '',
      last_message: [],
      sources_is_loading: false,
      date: undefined,
      dates: [],
      times: [],
      is_loading: true,
      chart: null,
      is_available: false,
      value_axis: [],
      channel: null,
      channels: [],
      button: null,
      source: [],
      sources: [],
      type: undefined,
      types: [],
      series: [],
      markers: [],
      selected_markers: [],
      marker_ranges: [],
      trend_marker_ranges: [],
      selectedMarkerRanges: [],
      main_value_axis: undefined,
      opposite_value_axis: undefined,
      alarms: [],
      alarmChecked: false,
      warnings: [],
      warningChecked: false,
      trend_colors: ["#0091FF", "#6DD400", "#6236FF", "#ff00c8", "#9B9B9B", "#44D7B6", "#0D7B80"],
      speed: 0,
      rms: 0,
      rms_unit: 'м/с<sup>2</sup>',
      main_spectrum: { source: undefined, type: undefined, channel: undefined },
      reference_spectra: [
        { source: undefined, type: undefined, channel: undefined },
        { source: undefined, type: undefined, channel: undefined },
        { source: undefined, type: undefined, channel: undefined }
      ],
      speedValue: null,
      maxSpeedCount: 0,
      selectedSpeedOption: 0,
      speedTimeout: null,
      speedOptions: [
        { label: 'Скрыть', value: 0 },
        { label: '1', value: 1 },
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
      ],
      reference_sources: [[], [], []],
      reference_times: [[], [], []],
      reference_events: [[], [], []],
      series_colors: [],
    }
  },
  mounted() {
    this.FetchMessages(this.device_id)
    this.fetchSpectraTypes()
    this.fetchLastMessage()
    this.createChart()
  },
  beforeDestroy() {
    this.chart.dispose()
  },
  methods: {
    ...mapActions(["FetchMessages"]),

    getDropdownLabel() {
      if ( !this.selectedMarkerRanges) return "Выбрать"

      if (this.selectedMarkerRanges.length === 0) return "Выбрать"
      else return this.selectedMarkerRanges.join(", ")
      // else if(this.selectedLegends.length < 4) return this.selectedLegends.join(', ')
      // else return `Выбрано: ${this.selectedLegends.length}`
    },
    selectAllMarkerRanges() {
      if (!this.trend_marker_ranges) return
      const allMarkerRangesSelected = this.trend_marker_ranges.length === this.selectedMarkerRanges.length
      if (!allMarkerRangesSelected) {
        this.selectedMarkerRanges = [...this.trend_marker_ranges.map(range => range.title)]
      } else {
        this.selectedMarkerRanges = []
      }

      this.handleTrendMarkerChange(!allMarkerRangesSelected)
    },
    onMarkerRangeCheck(checked, title) {
      this.trend_marker_ranges.forEach(marker => {
        if(marker.title === title) {
          if(checked) marker.range?.show()
          else marker.range?.hide()
        }
      })
    },

    createChart() {
      am4charts.ValueAxis.prototype.getSeriesDataItem = function(series, position) {
        var key = this.axisFieldName + this.axisLetter
        var value = this.positionToValue(position)
        return series.dataItems.getIndex(series.dataItems.findClosestIndex(value, function(x) {
          return x[key]
        }, "any"))
      }

      const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)

      //ось X
      this.value_axis = chart.xAxes.push(new am4charts.ValueAxis())
      this.value_axis.strictMinMax = true
      this.value_axis.extraTooltipPrecision = 2
      //ось Y
      // const valueY = this.createValueAxis(chart, 'm/s2')
      // valueY.tooltip.disabled = true

      chart.cursor = new am4charts.XYCursor()
      // chart.cursor.behavior = "zoomX"
      // chart.cursor.xAxis = this.value_axis

      // chart.zoomOutButton.disabled = true;

      // chart.legend = new am4charts.Legend();
      //верхний ползунок временного периода
      chart.scrollbarX = new am4core.Scrollbar()

      this.chart = chart
      this.chart.data = []
    },
    getTypeName(type) {
      return {
        env: 'Огибающая',
        vel: 'Скорость',
        acc: 'Ускорение'
      }[type]
    },
    onDateChange(date, index = null) {
      const _date = new Date(date)
      const keyDate = _date.toLocaleDateString('en-CA')
      if(index === null) {
        this.times = this.sources?.[keyDate] ?? []
        // this.main_spectrum.source = new Date(this.times[0] ?? date)
      } else {
        this.reference_times[index] = this.reference_sources[index]?.[keyDate] ?? []
        // this.reference_spectra[index].source = new Date(this.reference_times[index][0] ?? date)
      }

      this.date = new Date(this.times[0] ?? date)
    },
    getTimeOnly(date) {
      return new Date(date).toLocaleTimeString()
    },
    debouncedOnSpeedChange(event) {
      const value = Number(event)
      if(isNaN(value)) return
      clearTimeout(this.speedTimeout)
      this.speedTimeout = setTimeout(() => {
        if(value > this.maxSpeedCount) this.selectedSpeedOption = this.maxSpeedCount
        this.onSpeedChange(this.selectedSpeedOption)
      }, 1000)
    },
    onSpeedChange(event) {
      this.selectedSpeedOption = event
      for (const [key, value] of Object.entries(this.marker_ranges)) {
        if(key.includes('SPEED')) {
          this.value_axis.axisRanges.removeValue(value[0])
          delete this.marker_ranges[key]
        }
      }
      for (let i = 1; i <= event; i++) {
        const key = 'SPEED_' + i
        this.marker_ranges[key] = [...(this.marker_ranges[key] || []), this.createRange(i * this.speedValue, key, '#999')]
      }
    },
    async fetchLastMessage() {
      try {
        const { data } = await this.$axios.get(`user/agents/${this.device_id}/last-message`)
        this.last_message = data?.data || []
        this.device_name = data?.data?.device_name || ''
      } catch (err) {
        throw new Error(err)
      }
    },
    async fetchSpectraTypes() {
      this.is_loading = true
      try {
        const { data } = await this.$axios.get(`user/agents/${this.device_id}/spectrum-types`)
        this.is_available = data?.code === 200
        this.channels = data?.data?.channel || []
        this.types = data?.data?.type || []
      } catch (e) {
        throw new Error(e)
      }
      this.is_loading = false
    },
    async fetchSpectraSources(index = null) {
      this.sources_is_loading = true
      try {
        const payload = index === null ? this.main_spectrum : this.reference_spectra[index]
        const { data } = await this.$axios.post(`user/agents/${this.device_id}/spectrum-sources`, payload)
        if(index === null) {
          this.sources = data?.data?.grouped || {}
          this.dates = Object.keys(data?.data?.grouped ?? {}).map(date => new Date(date))
        }
        else {
          this.reference_sources[index] = data?.data?.grouped || []
          this.reference_events[index] = Object.keys(data?.data?.grouped ?? {}).map(date => new Date(date))
        }

      } catch (e) {
        throw new Error(e)
      }
      this.sources_is_loading = false
    },
    async fetchSpectra() {
      this.is_loading = true
      this.marker_ranges = []
      this.trend_marker_ranges = []
      this.selectedMarkerRanges = []
      // this.chart.dispose()
      // this.createChart()
      try {
        const { data } = await this.$axios.post(`user/agents/${this.device_id}/spectrums`, {
          spectrums: [
            this.main_spectrum,
            ...this.reference_spectra.filter(spectrum => Object.values(spectrum).every(value => !!value))
          ]
        })

        const graphData           = data?.['data'] || []
        const sourceNames         = data?.['names'] || []
        const trendNames          = data?.['trend_names'] || []
        const alarmWarningData    = data?.['alarm_warning'] || []
        const dataTypes           = data?.['types'] || []

        this.rms = data?.['rms'] || 0
        this.speed = data?.['speed'] || 0

        this.chart.data = [
          ...graphData,
          ...(alarmWarningData.length ? alarmWarningData : [])
        ] || []
        this.value_axis.axisRanges.clear()

        const seriesCount = this.chart.series.length
        for (let i = 0; i < seriesCount; i++) {
          this.chart.series.removeIndex(0).dispose()
        }
        const axisCount = this.chart.yAxes.length
        for (let i = 0; i < axisCount; i++) {
          this.chart.yAxes.removeIndex(0).dispose()
        }

        const isVelocity = dataTypes[0] === 'vel'
        if(isVelocity) {
          this.main_value_axis = this.createValueAxis('мм/с')
          this.rms_unit = 'мм/с'
        } else {
          if (dataTypes.includes('vel')) this.opposite_value_axis = this.createValueAxis('мм/с', true)
          this.main_value_axis = this.createValueAxis('м/с2')
          this.rms_unit = 'м/с2'
        }

        dataTypes.forEach((name, idx) => {
          const valueAxis = name === 'vel' ? isVelocity ? this.main_value_axis : this.opposite_value_axis : this.main_value_axis
          this.series_colors[idx] = this.trend_colors[idx]
          this.series = [
            ...this.series,
            this.createSeries(`source_${idx}_x`, `source_${idx}_y`, idx, data?.names?.length || 0, this.trend_colors[idx], name, true, valueAxis)
          ]

          if(alarmWarningData.length > 0 && idx === 0) {
            this.alarms = this.createSeries(`source_${idx}_x`, `alarm`, 0, sourceNames.length, '#FF0000', `alarm(${name})`, false, valueAxis, true)
            this.warnings = this.createSeries(`source_${idx}_x`, `warning`, 0, sourceNames.length, '#FFA500', `warning(${name})`, false, valueAxis, true)

            console.log(this.alarms, this.warnings)

            // this.$nextTick(() => {
            //   this.warnings?.hide()
            //   this.alarms?.hide()
            // })
          }

          if(trendNames.length > 0) {
            for (const trendName of trendNames) {
              const { name: trend_name, text } = trendName
              const rangeText = `${trend_name} | ${text}`
              if(trendName[`source_${idx}_x`]) {
                this.trend_marker_ranges = [...this.trend_marker_ranges, {
                  range: this.createRange(trendName[`source_${idx}_x`], rangeText, '#666', "top"),
                  title: rangeText
                }]
              }
            }

            this.handleTrendMarkerChange(false)
          }
        })

        const markerSet = new Set()
        const _markers = data?.["spectrum_markers"] || {}
        const markerKeys = Object.keys(_markers).filter(marker => marker !== 'SPEED')

        if(_markers?.hasOwnProperty('SPEED') && !!_markers['SPEED']) {
          this.speedValue = Number(_markers['SPEED'])
          const maxSourceInData = graphData?.at(-1)?.['source_0_x'] ?? this.speedValue
          if(maxSourceInData >= this.speedValue) {
            this.maxSpeedCount = Math.floor(maxSourceInData/this.speedValue)
          }
        }

        // if(markerKeys.includes('SPEED')) markerSet.add('SPEED')
        if(markerKeys.includes('BPFO')) markerSet.add('BPFO')
        if(markerKeys.includes('BPFI')) markerSet.add('BPFI')
        if(markerKeys.includes('BSF')) markerSet.add('BSF')
        if(markerKeys.includes('FTF')) markerSet.add('FTF')

        for (const key of markerKeys) {
          markerSet.add(key)
        }

        this.markers = [...markerSet]
        // this.selected_markers = [...markerSet]
        // SPEED, BPFO, BPFI, BSF, FTF

        Object.entries(_markers).forEach(([key, value]) => {
          // const randomColor = "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")
          if(key !== 'SPEED') {
            Object.entries(value).forEach(([mKey, mValue]) => {
              this.marker_ranges[key] = [...(this.marker_ranges[key] || []), this.createRange(mValue, mKey, '#999')]
              this.marker_ranges[key]?.forEach(range => range?.hide())
            })
          }
        })
      } catch (e) {
        throw new Error(e)
      }
      this.is_loading = false
      this.chart.validate()
      // await this.fetchReferenceSpectra()
    },
    async fetchReferenceSpectra() {
      this.is_loading = true

      for (const spectrum of this.reference_spectra) {
        if(Object.values(spectrum).every(value => !!value)) {
          const { source, channel, type } = spectrum
          try {
            const { data } = await this.$axios.post(`user/agents/${this.device_id}/spectrums`, {
              source, channel, type,
            })

            const graphData = data?.['data'] || []
            const dataTypes = data?.['types'] || []

            this.chart.data = [...this.chart.data, ...graphData]
            if(!this.opposite_value_axis && dataTypes[0] === 'vel') {
              this.opposite_value_axis = this.createValueAxis('мм/с', true)
              this.opposite_value_axis.syncWithAxis = this.main_value_axis
            }

            dataTypes.forEach((name, idx) => {
              this.series = [...this.series, this.createSeries(`source_${name}_x`, `source_${name}_y`, 1 + idx, data?.names?.length || 0, this.trend_colors[1 + idx], name, true)]
            })
          } catch (e) {
            throw new Error(e)
          }
        }
      }

      this.is_loading = false
      this.chart.validate()
    },
    handleMarkerChange(event, key) {
      this.marker_ranges[key]?.forEach(range => event ? range?.show() : range?.hide())
      // if(this.selected_markers.includes(key) && this.marker_ranges[key])
      //   this.marker_ranges[key].hide()
      // else
      //   this.marker_ranges[key].show()
    },
    handleTrendMarkerChange(event) {
      this.trend_marker_ranges.forEach(marker => {
        if(event) marker?.range?.show()
        else marker?.range?.hide()
      })
    },
    handleAlarmWarningChange(type, value) {
      if(type === 'alarm') {
        if(value) this.alarms.show()
        else this.alarms.hide()
      } else if(type === 'warning') {
        if(value) this.warnings.show()
        else this.warnings.hide()
      }
    },
    createSeries(valueX, valueY, index, count, color, name, fillLine, valueAxis, hidden) {
      const series = this.chart.series.push(new am4charts.LineSeries())
      series.dataFields.valueX = valueX
      series.dataFields.valueY = valueY
      series.stroke = color
      series.name = name || ''
      series.showOnInit = false
      series.hidden = hidden ?? false

      if(valueAxis) series.yAxis = valueAxis
      // else series.yAxis = this.main_value_axis

      // series.zIndex = 10
      series.align = "center"
      series.valign = "middle"
      series.tooltipText = "{name}: [bold]{valueY}[/]"
      // if(['alarm', 'warning'].includes(name)) {
      //   series.cursorTooltipEnabled = false
      // }
      // series.tooltipText = "{valueX}: [b]{valueY}[/]";
      series.tooltip.pointerOrientation = "vertical"
      // series.sequencedInterpolation = true
      series.tooltip.background.fillOpacity = 0.6
      // series.tooltipText = "[{stroke.hex}]●[/]{name}: [bold]{valueY}[/]"
      series.tooltip.getFillFromObject = false
      series.tooltip.background.fill = color
      series.tooltip.label.fill = am4core.color("#000")
      series.strokeWidth = 1
      series.fill = series.stroke;
      series.fillOpacity = fillLine ? 0.14 : 0
      series.dx = this.chart.depth / (count) * am4core.math.cos(this.chart.angle) * index
      series.dy = -this.chart.depth / (count) * am4core.math.sin(this.chart.angle) * index
      return series
    },
    createValueAxis(text, isOpposite = false, min) {
      let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.opposite = isOpposite
      valueAxis.showOnInit = true
      valueAxis.title.text = text
      valueAxis.title.rotation = 90
      valueAxis.title.valign = "top"
      valueAxis.title.fontWeight = '600'
      valueAxis.renderer.grid.template.strokeOpacity = 0.1
      valueAxis.extraTooltipPrecision = 2
      valueAxis.min = !isNaN(min) ? min : undefined
      // if(chart.yAxes.indexOf(valueAxis) !== 0){
      //   valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
      // }

      return valueAxis
    },
    createRange(value, text, color, align = "bottom") {
      const range = this.value_axis.axisRanges.create()

      range.value = value
      // range.endValue = value
      range.grid.above = true
      range.grid.stroke = color || am4core.color("#999")
      range.grid.strokeWidth = 2
      range.grid.strokeOpacity = 1
      range.label.inside = true
      range.label.text = text
      range.label.fill = range.grid.stroke
      range.label.rotation = -90
      range.label.valign = "top"
      range.label.verticalCenter = 'bottom'
      range.label.fontSize = 12
      range.label.fontWeight = "500"
      range.label.paddingBottom = 2

      if(align === 'bottom') {
        range.label.paddingRight = 100
        range.label.paddingBottom = -16
      } else if(align === 'top') {
        range.label.valign = 'middle'
        range.axisFill.tooltip = new am4core.Tooltip();
        range.axisFill.tooltipX = am4core.percent(50)
        range.axisFill.tooltipY = am4core.percent(0)
        range.axisFill.tooltipText = "[bold]{label.text}[]";
        range.axisFill.interactionsEnabled = true;
        range.axisFill.isMeasured = true;
      }

      return range
    },
    getMarkerLabel(marker) {
      return {
        SPEED: 'Скорость вращения\n',
        BPFO: 'Частоты вращения внешнего кольца\n',
        BPFI: 'Частоты вращения внутреннего кольца',
        BSF: 'Частоты вращения тел качения',
        FTF: 'Частоты вращения сепаратора',
      }[marker] ?? marker
    }
  },
}
</script>

<style>
.hello {
  width: 100%;
  height: 500px;
}

@media screen and (max-width: 700px) {
  .spectra__filters {
    flex-wrap: wrap;
  }
}

.spectrum-source__time > span, select {
  width: 100%;
}

</style>
